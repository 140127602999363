//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UserSearchInput',
  data: function data() {
    return {
      tableFromNew: this.value
    };
  },
  props: {
    value: {
      type: Object,
      default: {}
    }
  },
  watch: {
    tableFromNew: {
      handler: function handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  },
  methods: {
    handleChangeContent: function handleChangeContent(event) {
      if (this.tableFromNew.searchType === 'phone' || this.tableFromNew.searchType === 'uid') this.tableFromNew.content = event.replace(/[^0-9]/g, '');
      if (this.tableFromNew.content > 99999999999) {
        this.tableFromNew.content = ''; // 设置最大值为100
      }
    }
  }
};