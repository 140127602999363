var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        {
          staticClass: "selWidth",
          attrs: {
            placeholder: "请输入用户信息",
            type:
              _vm.tableFromNew.searchType === "phone" ||
              _vm.tableFromNew.searchType === "uid"
                ? "number"
                : "text",
            clearable: "",
          },
          on: { input: _vm.handleChangeContent },
          model: {
            value: _vm.tableFromNew.content,
            callback: function ($$v) {
              _vm.$set(_vm.tableFromNew, "content", $$v)
            },
            expression: "tableFromNew.content",
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100px" },
              attrs: { slot: "prepend" },
              slot: "prepend",
              model: {
                value: _vm.tableFromNew.searchType,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFromNew, "searchType", $$v)
                },
                expression: "tableFromNew.searchType",
              },
            },
            [
              _c("el-option", { attrs: { value: "all", label: "全部" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "uid", label: "用户ID" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "phone", label: "手机号" } }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { value: "nickname", label: "用户昵称" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }